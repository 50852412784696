import { postRaw } from "./network";

const NEW_ANALYTICS = "https://loggermycia.sdch.develondigital.com/v1/logs/widget";
const ENDPOINT = "https://logger.mycia.it/v2/logs/widget";

/**
 * Given the tracked data, it builds the tracking object containing all of the information
 * @param {(String|Number)} id business id
 * @param {String} token business token
 * @param {String} lang calendar widget language
 * @param {String} action action on calendar
 * @param {String[]} networks business networks
 * @param {Object} metadata metadata of the action
 * @returns tracking object
 */
export function buildTrackingObject(id, token, lang, action, networks = ["mycia"], metadata) {
    if (!id) {
        throw new Error("The business id is not defined");
    }
    if (!token) {
        throw new Error("The business token is not defined");
    }
    if (!lang) {
        throw new Error("The client language is not defined");
    }
    if (!action) {
        throw new Error("The user action is not defined");
    }

    let payload = {
        "action": action,
        "attributes": networks,
        "domain": window.location.origin,
        "lang": lang,
        "metadata": metadata ?? Object.assign({}, metadata),
        "referrer": window.document.referrer,
        "start": Date.now(),
        "url": window.location.href,
        "user_agent": navigator.userAgent,
    };

    return {
        "data": {
            "type": "widgetlog",
            "attributes": {
                "ris_id": id,
                "token": token,
                "action": action,
                "payload": payload,
            },
        },
    };
}

/**
 * Sends the tracked data to the backend
 * @param {(String|Number)} id business id
 * @param {String} token business token
 * @param {String} lang calendar widget language
 * @param {String} action action on calendar
 * @param {String[]} networks business networks
 * @param {Object} metadata metadata of the action
 * @returns tracking response
 */
export async function trackData(id, token, lang, action, networks, metadata) {
    let trackBody = buildTrackingObject(id, token, lang, action, networks, metadata);
    return await postRaw(ENDPOINT, trackBody);
}
